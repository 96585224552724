import pepe from "../assets/pepe-plane.gif";

function Services() {
  return (
    <div className="flex flex-col justify-center items-center">
      <img src={pepe} alt="Logo" className="mt-16 rounded-lg" />
      <div className="p-4">
        <h3 className="text-blue-600 font-black text-md text-center">
          *Contract Renounced! Tax 0/0, Liquidity locked
        </h3>
      </div>
    </div>
  );
}
export default Services;

import boeing from "../assets/boeing-plane.gif";
import cat from "../assets/cat.png";
import { motion } from "framer-motion";

function About() {
  return (
    <div className="flex flex-col justify-center items-center max-w-2xl mx-auto">
      <div>
        <p className="text-lg py-6">
          Engineered to perfection, hop on Board boeing fam. We're ready to
          reach the skies! ⛅
        </p>
        <p className="text-lg py-6">
          <span className="text-blue-600 font-black">$BOEING</span> is launched
          on Base; working to spread that{" "}
          <span className="text-blue-600 font-black">$BOEING</span> ticker all
          across twitter, telegram and beyond with the team pushing and giving
          constant updates.
        </p>
      </div>
      <div className="relative mt-16 overflow-visible">
        <img src={boeing} alt="boeing plane" className="w-full rounded-lg" />
        <motion.div
          className="absolute w-[80%] left-[50%] top-[-20%] translate-x-[-50%]"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1.4 }}
          viewport={{ once: true }}
        >
          <img src={cat} className="w-full" alt="you didn't see anything" />
          <h4 className="text-center py-2 px-3 bg-white border-[2px] border-blue-600 rounded-md">
            You didn't see nuthin
          </h4>
        </motion.div>
      </div>
    </div>
  );
}
export default About;

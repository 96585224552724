import React from "react";
import cloudsImage from "../assets/clouds.png";
import plane from "../assets/plane.webm";
import smoke from "../assets/smoke.webm";
import { motion } from "framer-motion";

function Header() {
  return (
    <header className="relative w-full flex flex-col justify-center items-center">
      <img
        src={cloudsImage}
        alt="clouds background"
        className="w-full object-contain absolute top-0 left-0 min-w-full -z-1"
      />
      <motion.div
        className="relative h-[400px] md:h-[700px] flex justify-center items-center w-full z-10 overflow-x-hidden overflow-hidden"
        initial={{ x: "50%" }}
        whileInView={{ x: 0 }}
        transition={{ duration: 2, delay: 0 }}
        viewport={{ once: true }}
      >
        <video
          src={smoke}
          autoPlay
          loop
          muted
          playsinline
          className="z-0 absolute -right-[50%] md:-right-[25%] max-h-[900px] -top-10 md:-top-96"
        />
        <video
          src={plane}
          autoPlay
          loop
          muted
          playsinline
          className="z-10 absolute -top-0 md:-top-60 max-h-[1000px] "
        />
      </motion.div>

      <div className="flex flex-col w-full gap-5 text-center justify-center items-center -translate-y-[50px] md:-translate-y-[160px] text-white">
        <h1 className="text-6xl md:text-7xl font-black drop-shadow-blue-md">
          $BOEING <br className="md:hidden" /> on Base
        </h1>
        <h2 className="text-md md:text-2xl drop-shadow-blue-sm">
          IF IT AIN'T BOEING, I AIN'T GOING
        </h2>
        <button className="text-lg font-black bg-blue-600 py-3 px-12 mt-4 rounded-full w-[90%] md:w-fit">
          Copy Contract ✈️
        </button>
        <div className="w-[90%] max-w-lg grid grid-cols-2 gap-x-2">
          <a
            href="https://t.me/yourtelegram"
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg font-black bg-white text-blue-600 py-3 px-12 rounded-full flex justify-center items-center w-full"
          >
            Telegram
            <span role="img" aria-label="message" className="ml-3">
              💬
            </span>
          </a>
          <a
            href="https://twitter.com/yourtwitter"
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg font-black bg-white text-blue-600 py-3 px-12 rounded-full flex justify-center items-center w-full"
          >
            Twitter
            <span
              role="img"
              aria-label="twitter"
              className="ml-3 -translate-y-[2px]"
            >
              🐥
            </span>
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;

import React from "react";
import logo from "../assets/logo-outline.svg";
import planeImg from "../assets/plane.png";

let array = [1, 2, "a", 3, "b"];

function numsOnly(list) {
  list.filter((num) => typeof num === "number");

  console.log(list);
  return list;
}

function Footer() {
  return (
    <footer className="text-white py-8 flex flex-col items-center justify-center w-[80%] mx-auto">
      <img src={planeImg} className="max-w-sm mb-4" alt="plane image" />
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="flex items-center mb-4 md:mb-0">
          <img src={logo} alt="Logo" className="h-12 mr-0 md:mr-10 w-40" />
        </div>
        <div className="flex flex-wrap gap-6 md:text-lg font-medium justify-center">
          <a
            href="https://t.me/yourtelegram"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </a>
          <a
            href="https://twitter.com/yourtwitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://dexscreener.com/yourlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dexscreener
          </a>
          <a
            href="https://birdeye.so/yourlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Birdeye
          </a>
          <a
            href="https://basescan.io/yourlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Basescan
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
